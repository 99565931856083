import type {ProfileAsSelector} from '@ambler/shared'
import {useApolloClient, useQuery} from '@apollo/client'
import type {ComponentType} from 'react'
import React from 'react'
import gql from 'graphql-tag'
import {useRouter} from 'next/router'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {useSessionStorage} from '../../hooks/use-storage'
import {LoadingPage, useNewAuth} from './auth'
import type {APP_profileQuery_, APP_profileQueryVariables} from './profile.generated'

/**
 * MFU Scope
 */

const MfuScopeContext = React.createContext<string>(null)

export const MfuScope: React.FC<{mfuId: string}> = ({mfuId, children}) => {
  return <MfuScopeContext.Provider value={mfuId}>{children}</MfuScopeContext.Provider>
}

const useMfuScope = () => {
  return React.useContext(MfuScopeContext)
}

/**
 * Profile
 */

const ProfileContext = React.createContext<APP_profileQuery_['profile']>({
  id: null,
  email: null,
  type: null,
  mfus: [],
  mts: [],
})

const profileQuery = gql`
  query APP_profileQuery($data: ProfileDataInput!) {
    profile(data: $data) {
      id
      email
      type
      mfus {
        id
        # Apollo caches objects without an "id" field with the object above.
        acl {
          canRead
          canWrite
          canSignFor
        }
        derived {
          hasSih
          preferredMfd {
            id
            type
            floor
            name
          }
          place {
            id
            mainText
            secondaryText
            address {
              id
              geopoint
            }
          }
          originFavorites {
            id
            mainText
            secondaryText
            address {
              id
              geopoint
            }
          }
          destinationFavorites {
            id
            mainText
            secondaryText
            address {
              id
              geopoint
            }
          }
        }
        mfu {
          id
          name
          hospitalType

          needAmbulance
          needVsl
          needTpmr
          needBariatric
          needParamedical
          needMedical

          needLdd
          needAutoOrder
          needPooling
          lddTransportCapacity
          needDelayedDispatch
          needSplitMissions
          needPayerDisplay
          needExports
          needIncidents
          needPatientReminder
          needPriorityDispatch

          # notifications
          needValidateGovFlatRates
          needOrderButton
          needPmtSignature
          needCerfaImport
          needRegulation
          needCancelPastOrder

          mf {
            id
            name
            timezone
            visitIdPattern
          }

          mfds {
            id
            type
            floor
            name
          }

          specificNeedsCustomizations {
            id
            vehicleType
            fields {
              id
              state
              label
            }
          }

          patientConditionsCustomizations {
            id
            vehicleType
            fields {
              id
              state
              label
            }
          }

          transportReasonCustomizationGroups {
            id
            index
            label
            transportReasonCustomizations {
              id
              label
              index
              reason
            }
          }

          customization {
            id

            # Patient step
            patientNirState
            patientNirLabel
            patientIppState
            patientIppLabel
            patientBirthdateState
            patientBirthdateLabel
            patientPhoneState
            patientPhoneLabel
            patientRoomState
            patientRoomLabel
            patientVisitIdState
            patientVisitIdLabel
            patientAddressState
            patientAddressLabel
            patientAddressDetailsState
            patientAddressDetailsLabel

            # Vehicle step
            ambulanceLabel
            vslLabel
            tpmrLabel
            bariatricLabel
            paramedicalLabel
            medicalLabel

            # Mission step
            missionMfuNotesState
            missionMfuNotesLabel
            missionDoctorState
            missionDoctorLabel
            missionChoiceForPayersLabel
            missionPreferredMtRules {
              id
              allowedArrivalMargin
              allowedDepartureMargin
              payers {
                id
                payer
              }
            }
          }
        }
      }
      mts {
        id

        # Apollo caches objects without an "id" field with the object above.
        acl {
          canRead
          canWrite
        }

        mt {
          id
          name
          type
        }
      }
    }
  }
`

const ProfileProvider: React.FC = ({children}) => {
  const router = useRouter()
  const {userId, isAuthenticated, isLoading} = useNewAuth()

  const urlConnectAs: ProfileAsSelector = (() => {
    if (router.query.forceMf) {
      return {
        type: 'MF',
        id: router.query.forceMf as string,
      }
    }

    if (router.query.forceMfu) {
      return {
        type: 'MFU',
        id: router.query.forceMfu as string,
      }
    }

    if (router.query.forceMt) {
      return {
        type: 'MT',
        id: router.query.forceMt as string,
      }
    }

    return undefined
  })()

  const [connectAs, setConnectAs, removeConnectAs] = useSessionStorage<ProfileAsSelector>('user-profile-as-selector')

  const variables = React.useMemo(
    () => ({
      data: {
        userId,
        ...(connectAs ? {as: connectAs} : undefined),
        ...(urlConnectAs ? {as: urlConnectAs} : undefined),
      },
    }),
    [connectAs, urlConnectAs, userId],
  )

  const handleCompleted = React.useCallback(
    (data: APP_profileQuery_) => {
      if (variables.data.as && data.profile.type === 'CONNECTED_AS') {
        setConnectAs(variables.data.as)
      } else {
        removeConnectAs()
      }
    },
    [removeConnectAs, setConnectAs, variables.data.as],
  )

  const query = useQuery<APP_profileQuery_, APP_profileQueryVariables>(profileQuery, {
    variables,
    onCompleted: handleCompleted,
    skip: isLoading || !isAuthenticated,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  })

  const client = useApolloClient()
  React.useEffect(() => {
    if (!isAuthenticated) {
      client.clearStore()
    }
  }, [client, isAuthenticated])

  const isProfileReady = Boolean(!query.loading && query.data?.profile)

  const [state, setState] = React.useState<'LOADING' | 'INVITED' | 'USER' | 'UNLINKED' | 'ANONYMOUS'>('LOADING')

  React.useEffect(() => {
    switch (state) {
      case 'LOADING':
        if (!isLoading && !isAuthenticated) {
          setState('ANONYMOUS')
          return
        }

        if (isProfileReady) {
          const profile = query.data?.profile

          if (profile.type === 'INVITED') {
            setState('INVITED')
          } else {
            if (profile.mfus.length === 0 && profile.mts.length === 0) {
              setState('UNLINKED')
            } else {
              setState('USER')
            }
          }
        }
        return
      case 'INVITED':
        if (['/account/unverified', '/logout'].includes(router.pathname)) {
          return
        }

        router.replace('/account/unverified')
        setState('USER')
        return
      case 'UNLINKED':
        if (['/account/unlinked', '/logout'].includes(router.pathname)) {
          return
        }

        router.replace('/account/unlinked')
        setState('USER')
        return
      case 'USER':
      case 'ANONYMOUS':
        return
    }
  }, [isAuthenticated, isLoading, isProfileReady, query.data?.profile, router, state])

  if (state === 'ANONYMOUS') {
    return <>{children}</>
  }

  if (state === 'LOADING') {
    return <LoadingPage message="Chargement du profil..." />
  }

  if (state === 'INVITED' && router.pathname !== '/account/unverified') {
    return <LoadingPage message="Redirection en cours..." />
  }

  return <ProfileContext.Provider value={query.data.profile}>{children}</ProfileContext.Provider>
}

export const withProfile = (Component: ComponentType) => {
  function WithProfile(props: any) {
    return (
      <ProfileProvider>
        <Component {...props} />
      </ProfileProvider>
    )
  }
  hoistNonReactStatics(WithProfile, Component)
  return WithProfile
}

export const useProfile = () => {
  const value = React.useContext(ProfileContext)

  if (process.env.NODE_ENV === 'development') {
    if (!value) {
      throw new Error('useProfile must be used within a ProfileProvider')
    }
  }

  return value
}

export const useMfuSettings = (overrideMfuId?: string) => {
  const profile = useProfile()

  const scopedMfuId = useMfuScope()
  const mfuId_ = overrideMfuId ?? scopedMfuId

  if (!mfuId_) {
    throw new Error('useMfuSettings is missing a mfuId. Either add MfuScope provider, or pass a mfuId prop')
  }

  const mfuProfile = profile.mfus.find(mfu => mfu.id === mfuId_)

  return {
    ...mfuProfile.mfu,
    ...mfuProfile.derived,
  }
}
