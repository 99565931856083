import type {ComponentType} from 'react'
import React from 'react'
import Head from 'next/head'

import gql from 'graphql-tag'
import {useQuery} from '@apollo/client'

import hoistNonReactStatics from 'hoist-non-react-statics'
import {FaviconRequests} from './ts/favicon-requests'
import type {A4T_FaviconQuery_, A4T_FaviconQueryVariables} from './favicon.generated'
import {useMainMt} from './stack/auth'

const faviconQuery = gql`
  query A4T_FaviconQuery($mtId: ID!) {
    bookingRequests: myBookingRequests(mtId: $mtId) {
      id
      ...A4T_FaviconRequestsBookingRequestFragment
    }
    changeRequests: myChangeRequests(mtId: $mtId) {
      id
      ...A4T_FaviconRequestsChangeRequestFragment
    }
  }
  ${FaviconRequests.fragments.bookingRequests}
  ${FaviconRequests.fragments.changeRequests}
`

const Favicon = () => {
  const [customizeFavicon, setCustomizeFavicon] = React.useState(false)
  const mainMt = useMainMt()
  const mtId = mainMt?.mt.id

  const {data} = useQuery<A4T_FaviconQuery_, A4T_FaviconQueryVariables>(faviconQuery, {
    variables: {mtId},
    skip: !mtId,
    fetchPolicy: 'cache-and-network',
  })

  const bookingRequests = data?.bookingRequests ?? []
  const changeRequests = data?.changeRequests ?? []

  return (
    <>
      <FaviconRequests
        bookingRequests={bookingRequests}
        changeRequests={changeRequests}
        mtId={mtId}
        onCustomize={(customize: React.SetStateAction<boolean>) => {
          setCustomizeFavicon(customize)
        }}
      />

      {!customizeFavicon && (
        <Head>
          <link rel="apple-touch-icon" sizes="57x57" href="/static/favicon/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/static/favicon/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/static/favicon/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/static/favicon/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/static/favicon/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/static/favicon/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/static/favicon/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/static/favicon/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192" href="/static/favicon/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/static/favicon/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
        </Head>
      )}
    </>
  )
}

export const withFavicon = (Component: ComponentType) => {
  function WithFavicon(props: any) {
    return (
      <>
        <Favicon />
        <Component {...props} />
      </>
    )
  }
  hoistNonReactStatics(WithFavicon, Component)
  return WithFavicon
}
