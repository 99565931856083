import React from 'react'

type storageType = 'localStorage' | 'sessionStorage'

function useStorage<T>(storage: storageType, key: string, initialValue?: T) {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      const item = window[storage].getItem(key)
      if (!item) {
        return initialValue
      }
      try {
        return item ? JSON.parse(item) : undefined
      } catch (error) {
        // * in case the value was not valid JSON (i.e. set before we introduced this hook)
        return item
      }
    } catch (error) {
      console.error(error)
      return initialValue
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (typeof window !== 'undefined') {
        if (typeof valueToStore === 'undefined') {
          window[storage].removeItem(key)
        } else {
          window[storage].setItem(key, JSON.stringify(valueToStore))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const removeValue = () => {
    try {
      setStoredValue(null)
      if (typeof window !== 'undefined') {
        window[storage].removeItem(key)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue, removeValue] as const
}

export const useSessionStorage = <T,>(key: string, initialValue?: T) => useStorage('sessionStorage', key, initialValue)
