import {Capacitor} from '@capacitor/core'

export const getBundleId = () => {
  switch (Capacitor.getPlatform()) {
    case 'ios':
      return process.env.BUNDLE_ID_IOS
    case 'android':
      return process.env.BUNDLE_ID_ANDROID
    default: {
      return null
    }
  }
}
