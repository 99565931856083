import type {ComponentType} from 'react'
import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {safeLocalStorage} from '../../lib/local-storage'

/**
 * Multi MFU Context (old way, visualise 1 MFU at a time in the app)
 */
export const MultiMfuContext = React.createContext({
  currentMfuId: null,
  setCurrentMfuId: null,
})

const A4H_MULTIMFU_CURRENT_ID = 'a4h.dashboard.lastUsedMfuId'

export const useMultiMfu = () => React.useContext(MultiMfuContext)

export const withMultiMfu = (Component: ComponentType) => {
  function WithMultiMfu(props: any) {
    const [currentMfuId, setCurrentMfuId] = React.useState(() => safeLocalStorage.getItem(A4H_MULTIMFU_CURRENT_ID))

    React.useEffect(() => {
      safeLocalStorage.setItem(A4H_MULTIMFU_CURRENT_ID, currentMfuId)
    }, [currentMfuId])

    const value = React.useMemo(
      () => ({
        currentMfuId,
        setCurrentMfuId,
      }),
      [currentMfuId],
    )

    return (
      <MultiMfuContext.Provider value={value}>
        <Component {...props} />
      </MultiMfuContext.Provider>
    )
  }
  hoistNonReactStatics(WithMultiMfu, Component)
  return WithMultiMfu
}
