import {useRouter} from 'next/router'
import type {URLOpenListenerEvent} from '@capacitor/app'
import {App} from '@capacitor/app'
import {useAuth0} from '@auth0/auth0-react'
import {Capacitor} from '@capacitor/core'
import {Browser} from '@capacitor/browser'
import {hideSplashScreen} from '../../lib/mobile/splash-screen'
import {auth0Params} from '../stack/auth'
import {useOnce} from '../../hooks/use-once'
import {getBundleId} from '../../lib/mobile/bundle-id'

const useAppUrlListeners = () => {
  const router = useRouter()
  const {handleRedirectCallback} = useAuth0()

  useOnce(() => {
    if (!process.env.IS_MOBILE_BUILD) {
      return null
    }

    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const host = new URL(process.env.AMBLER_APP_URL).host
      if (event.url.startsWith(auth0Params.redirect_uri)) {
        // * Auth0 callback
        // eg: fr.amblea.amblea://auth0.ambler.fr/capacitor/fr.amblea.amblea/callback?code=xxx,state=zzz
        // eg: fr.amblea.amblea://auth0.ambler.fr/capacitor/fr.amblea.amblea/callback/authcallback?code=xxx,state=zzz
        if (event.url.includes('state') && (event.url.includes('code') || event.url.includes('error'))) {
          try {
            await handleRedirectCallback(event.url)
          } catch (_err) {
            // Do nothing.
          }
        }

        if (event.url.startsWith(`${auth0Params.redirect_uri}/authcallback`)) {
          // * Should be a login or signup
          await router.replace(event.url.split(auth0Params.redirect_uri).pop())
        } else {
          // * And this is bascially a logout
          await router.replace('/')
        }

        if (Capacitor.getPlatform() === 'ios') {
          try {
            // ? no op on android (but closes itself anyway)
            await Browser.close()
          } catch (_error) {
            /* empty */
          }
        }
      } else if (event.url.startsWith(process.env.AMBLER_APP_URL)) {
        // * Deep links
        // eg: https://app.amblea.fr/ts/amblea-invoice/clprip4cu002zqrffy1tkjuwo
        const url = new URL(event.url)

        await router.replace(`${url.pathname}${url.search}`)
        hideSplashScreen()
      } else if (event.url.startsWith(`${getBundleId()}://${host}`)) {
        // * Custom scheme
        // eg: fr.amblea.amblea://app.amblea.fr/ts/amblea-invoice/clprip4cu002zqrffy1tkjuwo
        await router.replace(event.url.split(`${getBundleId()}://${host}`).pop())
        hideSplashScreen()
      }
    })
  })
}

export default useAppUrlListeners
