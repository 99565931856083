import React from 'react'
import type {ApolloClient, NormalizedCacheObject} from '@apollo/client'
import {ApolloProvider} from '@apollo/client'
import withNextApollo from 'next-with-apollo'

import type {FixType} from '@ambler/shared'
import type {NextPage} from 'next'
import {createApolloClient} from './init-apollo'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

const initializeApolloClient = (initialState: FixType = null): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient = apolloClient ?? createApolloClient()
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({...existingCache, ...initialState})
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }
  return _apolloClient
}

const render = ({Page, props}: FixType) => {
  return (
    <ApolloProvider client={props.apollo}>
      <Page {...props} />
    </ApolloProvider>
  )
}

/**
 * next-with-apollo is a simple HoC managing the apollo instance for both the
 * server and client, accepting optionals parameters (mainly getInitialProps
 * and getDataFromTree resulting in the opting out of SSG optimizations).
 * See https://github.com/lfades/next-with-apollo/blob/master/src/withApollo.tsx
 */
export const withNextApolloClient = withNextApollo(
  ({initialState}) => {
    return initializeApolloClient(initialState)
  },
  {
    render,
  },
)

export const withApollo = () => (PageComponent: NextPage) => {
  return PageComponent
}
