import type {ComponentType} from 'react'
import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import Sentry from '../../lib/sentry'
import {useProfile} from './profile'

export const withSentryUser = (Component: ComponentType) => {
  function WithSentryUser(props: any) {
    const profile = useProfile()

    React.useEffect(() => {
      if (!profile.id) {
        return
      }

      Sentry.setUser({
        id: profile.id,
        email: profile.email,
        extra: {
          isEmailConfirmed: profile.type !== 'INVITED',
          // if we don't use JSON.stringify, Sentry will not show the nested object
          mtAcls: JSON.stringify(
            profile.mts.map(mt => mt.acl),
            null,
            2,
          ),
          mfuAcls: JSON.stringify(
            profile.mfus.map(mfu => mfu.acl),
            null,
            2,
          ),
        },
      })
    }, [profile])

    return <Component {...props} />
  }

  hoistNonReactStatics(WithSentryUser, Component)
  return WithSentryUser
}
